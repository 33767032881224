import {Component} from 'react'
import axios from "axios";
import {config} from "../config/config";
import {createSignature} from "../utils/encrypt-functions/create-signature";
// import {getTestData} from "../utils/helper-functions/get-test-data";
import {delay} from "../utils/helper-functions/delay";
import {getUnixTimestamp} from "../utils/helper-functions/get-unix-timestamp";
import store from "../state/store";
import {perfBreak} from "../utils/performance-functions/perf-break";
import {adler} from "../utils/encrypt-functions/adler32-encrypt";
import {getDeviceData} from "../utils/helper-functions/get-device-data";
import {getTokenFromUrl} from "../utils/helper-functions/getTokenFromUrl";
import {prodTheme} from "../variables/prod-theme";
import history from "../variables/history";
import {isChineseDomain} from "../variables/is-chinese-domain";
import {getUrl, isTest} from "../variables/host";
import { sendOpenSearchLogs } from "utils/helper-functions/send-open-search-logs.ts";
import {isProd} from "../variables/app-variables";

let getTestData = () => {};

if (!isProd) {
    getTestData = require('../utils/helper-functions/get-test-data').getTestData;
}

const testTheme = (token) => isProd ? prodTheme : getTestData("get-asset-data", is_documentation(token));

const is_documentation = (token) => {
    return(token === "f3fd281d1b8af07e9ccb4c9c80dfc3ba58b10c9a-1"
        && config.api === "https://hermes.cashier-dev.com/api/")
};

let isSocket = 0;
let socket = null;
const current_api = getUrl('api');
const current_apiTheme = isChineseDomain ? 'https://compute.prx-pay.com/hermes-assets/' : getUrl('apiTheme');

const getBasicParams = () => {
    const {token, initTheme:{communication_token, session_duration, session_theme}} = store.getState();
    const timestamp = getUnixTimestamp(new Date().getTime());
    return {token, communication_token, session_duration, session_theme, timestamp}
}

let time_info = {};
const cacheData = {};
let loading_time = 0;
let restarted = false;

export default class PraxisService extends Component {

    timeInfoUpdate = (arg = {}) => {
        if(!arg || typeof arg !== 'object') return;
        time_info = {...time_info, ...arg}
    }

    getCompute = async(token) => {
        loading_time = perfBreak();
        if(isTest || store.getState().location.test === "1" || config.skipComputeRequest){
            console.log("request", "get-compute", {token}, "response", testTheme(token));
            const res = await delay(testTheme(token))
            const {data:{enable_websockets}} = res
            if(enable_websockets) isSocket = enable_websockets;
            return res
        }

        let options = {
            method: "get",
            url: `${current_apiTheme}${token}`,
            data: {token}
        };

        let res;
        try {
            res = await axios(options);
        } catch(e) {
            sendOpenSearchLogs({
                event_group: "cashier_get_compute",
                error_data: e
            });
            const {name, message} = e;
            this.postComputeLogs({name, message}).catch(err => console.error('Error logging compute success:', err));
            return;
        }

        const {data, data:{enable_websockets}} = res;
        if(enable_websockets) isSocket = enable_websockets;

        this.postComputeLogs(data).catch(err => console.error('Error logging compute success:', err));
        return res;
    }

    cashierAxios = async(url, data, delayTime, cache, method="post") => {
        let cacheKey;
        if(cache) cacheKey = adler(url + JSON.stringify(data))

        data = {...getBasicParams(), ...data}
        if(isTest) {
            return await this.fetchTestData({data, action: url, requestType: 'https', cache, cacheKey, delayTime})
        }

        if(isTest) {
            const {option_id} = data;
            console.log("https-request", url, data, "https-response", getTestData(url, option_id && {option_id}));
            if(cache && cacheData[cacheKey]) return cacheData[cacheKey];
            const res = await delay(getTestData(url, data), delayTime);
            if(cache) cacheData[cacheKey] = res;
            return res;
        }

        let options = {
            method,
            url: `${current_api}cashier/${url}`,
            data: {...data, signature: createSignature(data)}
        };

        if(cache && cacheData[cacheKey]) return cacheData[cacheKey];
        const res = await axios(options)
        if(cache) cacheData[cacheKey] = res;
        return res
    }

    webSocketRequest = async(action, data, delayTime, cache) => {
        let cacheKey;
        if(cache) cacheKey = adler(action + JSON.stringify(data))

        data = {...getBasicParams(), ...data}
        const body = {...data, signature: createSignature(data)}

        if(isTest) {
            return await this.fetchTestData({data, action, requestType: 'wss', cache, cacheKey, delayTime})
        }
        if(cache && cacheData[cacheKey]) return {data:cacheData[cacheKey]};
        const res = await this.createWebSocket(action, body, data.token)
        if(cache) cacheData[cacheKey] = res;
        return {data:res};
    }

    createWebSocket = (action, body) => {
        const socketUrl = getUrl('socketUrl');

        if(socket === null) {
            socket = new WebSocket(`${socketUrl}?token=${getTokenFromUrl()}`)
        }
        return new Promise((resolve) => {
            if(socket.readyState === 0){
                socket.onopen = () => {
                    setTimeout(() => {
                        socket.send(JSON.stringify({action: 'ping', body:{}}));
                    }, 9 * 60 * 1000);
                    socket.send( JSON.stringify({action, body}))
                }
            } else if (socket.readyState === 1) {
                socket.send( JSON.stringify({action, body}))
            } else {
                history.push('error_7?back=false')
            }
            socket.onclose = () => {
                socket = new WebSocket(`${socketUrl}?token=${getTokenFromUrl()}`)
                restarted = true
            }
            socket.onmessage = ({data}={}) => {
                const {body, action:act} = JSON.parse(data);
                if (restarted && body.status === 2) {
                    history.push('error_7?back=false')
                }
                if(act === action) resolve(body);
            };
        })
    }

    fetchTestData = async({data = {}, action, requestType = '', cache, cacheKey, delayTime = 0}) => {
        const {option_id} = data;
        console.log(`${requestType}-request`, action, data, `${requestType}-response`, getTestData(action, option_id && {option_id}));
        if(cache && cacheData[cacheKey]) return cacheData[cacheKey];
        const res = await delay(getTestData(action, data), delayTime);
        if(cache) cacheData[cacheKey] = res;
        return res;
    }

    cashierRequest = async (url='', data={}, delayTime=0, cache=false, method='post') => {
        return isSocket ? this.webSocketRequest(url, data, delayTime, cache) :
            this.cashierAxios(url, data, delayTime, cache, method)
    }

    getServerOptions = (data) => this.cashierAxios('get-dynamic-options', data, 2000, true)

    serverGetTransactions = () => this.cashierRequest('get-transactions')

    serverDeleteTransaction = (trace_id) => this.cashierRequest('delete-transaction', {trace_id})

    postErrors = (data) => {
        sendOpenSearchLogs({
            error_data: data
        });

        return this.cashierAxios('errors', data);
    }

    saveData = (data) => this.cashierRequest('save-data', data);

    postLoadingTime = (loading_time) => {
        const data = {loading_time: `${loading_time}`, time_info }
        return this.cashierRequest('long-query', data)
    }

    postPayGateway = (data) => {
        const {gatewayInfo:{method}} = store.getState();
        const device_data = method === 'card-method'? getDeviceData() : null;
        return this.cashierRequest('pay-with-gateway', {...data, device_data});
    };

    getAvailableGateways = async(data = {}) => {
        const {payInfo: {pciPraxisGate}} = store.getState();
        const {getAvailableGateways} = pciPraxisGate;
        if(isTest) return await this.fetchTestData({action: 'get-processing-gateway-list', data, requestType:'sdk'})
        return await getAvailableGateways({...data});
    };

    getOBTMethods = async (data) => {
        if(isTest) return await this.fetchTestData({action: 'get-available-obt-methods', data: {}, requestType:'sdk'});
        
        const {payInfo: { pciPraxisGate }} = store.getState();
        const { getAvailableOBTMethods } = pciPraxisGate;
        return await getAvailableOBTMethods(data);
    } 

    getGateways = async(type, ob_decline_recovery= 0, retry_with_apm = 0) => {
        let url;
        const isPayOut = type === "pay-out";

        if(isSocket) {
            url = isPayOut ? `get-gateway-list` : 'get-payment-method-list';
        } else {
            url = isPayOut ? `get-gateway-list-without-details` : 'get-payment-method-list-without-details';
        }

        const start = perfBreak()
        const res = await this.cashierRequest(url,{ob_decline_recovery, apm_decline_recovery: retry_with_apm});
        const end = (perfBreak() - start).toFixed(3);
        this.timeInfoUpdate({getPaymentMethodList:end})

        loading_time = (perfBreak() - loading_time).toFixed(3);
        if(loading_time > 8) this.postLoadingTime(loading_time)

        if(!isPayOut) {
            const {
                gateways,
                is_new_window_mobile,
                is_bdcc_enabled,
                is_bdcc_window,
                cvv_input_as_buttons,
                is_encrypted_input_names,
                send_get_available_gateways_request
            } = res.data;

            sendOpenSearchLogs({
                event_group: "cashier_initialization",
                event_name: "get_available_payment_methods",
                event_data: {
                    payment_methods: gateways?.map(gate => {
                        const {
                            image,
                            image_dark_mode,
                            title,
                            option_id,
                            option_type,
                            payment_method_type,
                            payment_method_key
                        } = gate;

                        return {
                            image,
                            image_dark_mode,
                            title,
                            option_id,
                            option_type,
                            payment_method_type,
                            payment_method_key
                        };
                    }),
                    is_new_window_mobile,
                    is_bdcc_enabled,
                    is_bdcc_window,
                    cvv_input_as_buttons,
                    is_encrypted_input_names,
                    send_get_available_gateways_request
                },
                event_context: {
                    response_code: res.data.status,
                    http_status_code: res.status,
                }
            });
        }

        return res
    };

    getGatewayDetails = async(data={}) => {
        const {option_type, option_id} = data;
        let params = {option_type, option_id};
        return await this.cashierRequest('get-gateway-details', {...params}, 3000);
    }

    postComputeLogs = (response={}) => {
        const {token, timestamp} = getBasicParams();
        const request = {token};
        const {communication_token, session_duration, session_theme} = response;
        const compute_url = current_apiTheme;
        const data = {
            request, response, compute_url, timestamp, token,
            communication_token, session_duration, session_theme
        };
        return this.cashierRequest('compute-request', data);
    };

    getUser = async(callback) => {
        const start = perfBreak()
        const res = await this.cashierRequest('get-customer-data', {callback})
        const end = (perfBreak() - start).toFixed(3);
        this.timeInfoUpdate({getCustomerData:end})
        return res
    };

    partialApprovedResult = (data) => this.cashierRequest('partial-approve', data, 3000);

    manageRetryRequest = (data) => this.cashierRequest('manage-retry-request', data, 1000);
}
